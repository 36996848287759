import type { LocalePrefixMode } from 'node_modules/next-intl/dist/types/src/routing/types';

const localePrefix: LocalePrefixMode = 'as-needed';

export const AppConfig = {
    name: 'Hus.sh',
    locales: ['en', 'ru'],
    defaultLocale: 'en',
    localePrefix,
};

export const ApiEndpoints = {
    auth: {
        LOGIN: 'auth/login',
        SIGN_IN: 'auth/sign-in',
        LOGOUT: 'auth/logout',
        SIGN_UP: 'auth/sign-up',
        CHECK: 'auth/check',
        CHECK_ACCESS: 'auth/check-dashboard-access',
    },
    public: {
        GET_USER: 'public/get-user',
        GET_USER_PROFILE: 'public/get-user-profile',
        SEARCH_USER: 'public/search-user',
        GET_RELATED_USERS: 'public/get-related-users',
        DETECT_PLATFORM: 'public/detect-platform',
        GET_PLATFORMS: 'public/get-platforms',
        CONTACT_PAGE_DATA: 'public/get-contact-page-data',
    },
    dashboard: {
        GET_USER_AVATAR: 'dashboard/user/avatar/get',
        SAVE_USER_AVATAR: 'dashboard/user/avatar/save',
        EDIT_USER_AVATAR: 'dahboard/user/avatar/edit',
        DELETE_USER_AVATAR: 'dashboard/user/avatar/delete',
        GET_USER_ABOUT_TEXT: 'dashboard/user/about-text/get',
        SAVE_USER_ABOUT_TEXT: 'dashboard/user/about-text/save',
        EDIT_USER_ABOUT_TEXT: 'dashboard/user/about-text/edit',
        DELETE_USER_ABOUT_TEXT: 'dashboard/user/about-text/delete',
        GET_USER_LINKS: 'dashboard/user/links/get',
        SAVE_USER_LINKS: 'dashboard/user/links/save',
        EDIT_USER_LINKS: 'dashboard/user/links/edit',
        DELETE_USER_LINKS: 'dashboard/user/links/delete',
        GET_USER_FRIENDS: 'dashboard/user/friends/get',
        SAVE_USER_FRIENDS: 'dashboard/user/friends/save',
        EDIT_USER_FRIENDS: 'dashboard/user/friends/edit',
        DELETE_USER_FRIENDS: 'dashboard/user/friends/delete',
        SAVE_USERNAME: 'dashboard/user/profile/username/save',
        DEACTIVATE_PROFILE: 'dashboard/user/profile/deactivate',
        RESTORE_PROFILE: 'dashboard/user/profile/restore',
    },
    service: {
        LOGGER: 'service/logger',
        VALIDATE_URL: 'service/validate-url',
        TEST_GET: 'service/test/get',
        TEST_POST: 'service/test/post',
        GET_GAVYN_PAGE_DATA: 'service/get-gavyn-page-data',
    },
};
